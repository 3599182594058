import React from "react";
import logo from "../src/Assests/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light color">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img
            src={logo}
            alt="roccolive"
            className="logo animate__animated animate__bounce"
          />
        </Link>

        {/* Navbar Toggle Button for Mobile */}
        <button
          className="navbar-toggler bg-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="true"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Links */}
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className=" pu m-4">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className=" pu m-4">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/team" className=" pu m-4">
                Team Members
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className=" pu m-4">
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <a
                href="https://apk.support/app/prod.thinkodigtal.com"
                className="btn btn-dark mm"
              >
                Download
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </div>
  );
};

export default Navbar;
