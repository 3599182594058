import React from 'react'
import coming from '../src/Assests/coming.gif'

const Coming = () => {
  return (
    <div className='com  color text-white text-center'>
    <div className="container">

      <img src={coming} alt="" className='com1'/>
    </div>
    </div>
  )
}

export default Coming
