import React from 'react'
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { IoLogoInstagram } from "react-icons/io";
import { MdOutgoingMail } from "react-icons/md";
import {
  Link, 
} from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer className="color2 text-white pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <h3>Safety Center</h3>
              <Link to="/community" className='link-light pu'><p>Community Guidelines</p></Link>
            </div>
            <div className="col-lg-4">
              <h3>Legal</h3>
              <Link to="/terms" className='link-light pu'><p>Terms of Service</p></Link>
              <Link to="/Privacy" className='link-light pu'><p>Privacy Policy</p></Link>
            </div>
            <div className="col-lg-4">
            <a href="https://apps.apple.com/pk/app/roccovideo-fun-videos-go-live/id6474921968" className="btn btn-light  my-4">
                <FaApple className="app"/> App Store
              </a>
              <a href="https://play.google.com/store/apps/details?id=prod.thinkodigtal.com " className="btn btn-light m-4"><FaGooglePlay className='app1'/>Google Play</a>
            </div>
          </div>
        </div>
        <div className="text-center">
          <span className="copy">Copyright © 2023-2024 rk. All Rights Reserved.</span>
          <a href="https://www.facebook.com/people/Rocco-Video/61550081224727/?mibextid=LQQJ4d" className='text-white'><CiFacebook/></a>
          <a href='https://www.instagram.com/roccovideo/?igshid=MzMyNGUyNmU2YQ%3D%3D&utm_source=qr' className='text-white'><IoLogoInstagram /></a>
          <a href='https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@roccovideo.com' className='text-white'><MdOutgoingMail /></a>
        </div>
      </footer>
    </div>
  )
}

export default Footer
