import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import "./App.css";
import Rocco from './Rocco';
import About from './About';
import Community from './Community';
import Terms from './Terms';
import Privacy from './Privacy';
import Footer from './Footer';
import Navbar from './Navbar';
import Team from './Team';
import Coming from './Coming';
import Contact from './Contact';

function App() {
  return (
    
    <BrowserRouter basename={"/"}>
      <div className="body">
        <Navbar />
        {/* <Rocco/> */}
        <Routes>
          <Route exact path="/" element={<Rocco/>}/>
          <Route path="/about" element={<About/>} />
          <Route path="/community" element={<Community/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/Privacy" element={<Privacy/>} />
          <Route path="/team" element={<Team/>} />
          <Route path="/coming" element={<Coming/>} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

          