import React from 'react'
import contact from '../src/Assests/contact.webp'

const Contact = () => {
  return (
    <>
      <div className='color text-white '>
      <div className="container p-5 ">
        <div className="row">
            <div className="col-lg-6">
            <img src={contact} alt="" height={300} width={450} className='contact'/>
            </div>
            <div className="col-lg-6">
                <h1>Contact Us</h1><hr />
                <h4>New Agency Cooperation</h4>
                <p>320 Serangoon Road <br />
#17-09/10/11 Centrium Square <br />
Singapore 218108</p>
                <p className='bus'>Business cooperation</p>
                <h4>info@roccovideo.com</h4>
                <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@roccovideo.com"
                    className=" btn btn-outline-light px-5"
                  >
                    <b className='px-4'>Contact Us</b>
                  </a>
            </div>
        </div>
      </div>

    </div>
    </>
  )
}

export default Contact
