import React from "react";
import ahmed from "../src/Assests/ahmed.webp";
import nouman from "../src/Assests/nouman.jpg";
import faheem from "../src/Assests/Huy.jpg";
import asad from "../src/Assests/Noel.jpg";
import Muzammil from "../src/Assests/Linh.jpg";
import shan from "../src/Assests/shan.jpg";

const Team = () => {
  return (
    <>
      <div className="color text-white mteam">
        <div className="container text-center pt-5 pb-5">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/* card1 */}
              <div className="card1">
                <img
                  className="card-img-top"
                  src={ahmed}
                  alt="jsx-a11y/img-redundant-alt"
                />
                <div className="card-body">
                  <h5>Ahmed Arif</h5> <hr />
                  <p className="card-text">
                  Marketing Manager at Rocco Video
                  </p>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=ahmad@roccolives.com"
                    className=" btn btn-outline-light"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/* card2     */}
              <div class="card1">
                <img
                  class="card-img-top"
                  src={nouman}
                  alt="jsx-a11y/img-redundant-alt"
                />
                <div class="card-body">
                  <h5>Nouman Saeed</h5> <hr />
                  <p class="card-text">South Asia Manager at Rocco Video</p>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=nouman@roccolives.com"
                    className="btn btn-outline-light"
                  >Conatct Us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/* card3 */}
              <div class="card1">
                <img
                  class="card-img-top"
                  src={shan}
                  alt="jsx-a11y/img-redundant-alt"
                />
                <div class="card-body">
                  <h5>Shan Ali</h5> <hr />
                  <p class="card-text">Web Developer at Rocco Video</p>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=shan@roccolives.com"
                    className="btn btn-outline-light"
                  >Conatct Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/* card1 */}
              <div class="card1">
                <img
                  class="card-img-top"
                  src={faheem}
                  alt="jsx-a11y/img-redundant-alt"
                />
                <div class="card-body">
                  <h5>Huy Nguyen</h5> <hr />
                  <p class="card-text">Project Manager at Rocco Video</p>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=huy@roccolives.com"
                    className="btn btn-outline-light"
                  >Conatct Us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/* card2     */}
              <div class="card1">
                <img
                  class="card-img-top"
                  src={asad}
                  alt="jsx-a11y/img-redundant-alt"
                />
                <div class="card-body">
                  <h5>Noel</h5> <hr />
                  <p class="card-text">Team Lead at Rocco Video</p>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=noel@roccolives.com"
                    className="btn btn-outline-light"
                  >Conatct Us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              {/* card3 */}
              <div class="card1">
                <img
                  class="card-img-top"
                  src={Muzammil}
                  alt="jsx-a11y/img-redundant-alt"
                />
                <div class="card-body">
                  <h5>Linh Kim Huynh</h5> <hr />
                  <p class="card-text">UI/UX Designer at Rocco Video</p>
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=linh@roccolives.com"
                    className="btn btn-outline-light"
                  >Conatct Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
