import React from 'react'

const Terms = () => {
  return (
    <div>
      <div className='color text-white pb-5 pt-5'>
    <div className='container '>
      <h1 className='text center'>Privacy Policy</h1>
      <p>We and our affiliates " referred as to “We”: respect your and takes responsibility in protecting your information. Through this Privacy Policy "Refer to as “This Policy”:, we intend to remind you"
This Policy does not apply to services provided by other third-parties, nor to products and services attached with a separate privacy policy provided from Us.</p>
<p>Please read and understand this Privacy Policy carefully before using our Services. When you use our Services, you agree to be bound by these terms and conditions. If you have any enquires or suggestions regarding terms within This Policy, you are free to contact Us through any means provided by this Service.</p>
<p>We reserve the right, at our discretion, to this Policy by posting the amended contents on our sites or within the Services. New versions of the policies will be accessible from within the Services. If you continue to use the Services after the changes are posted, you are agreeing that the changes apply to your continued use of the Services. Otherwise, if you do not agree to these terms or any future updated version then you must not access and/or use, and must cease all access and/or use of, any of our Services.</p>
<p>This Policy will help you understand the following:</p>
<ol>
  <li>How do we collect information?</li>
  <li>How do we use this information?</li>
  <li>Cookies and other similar data collection technologies</li>
  <li>How is this information shared?</li>
  <li>How do we protect your information?</li>
  <li>How do you manage your own information?</li>
  <li>How do we protect information of underage users?</li>
  <li>How do we operate and transfer data as part of our global services?</li>
  <li>How to contact us?</li>
</ol>
<h3>1. How do we collect information?</h3>
<p>To provide Services, we must process information about you. We collect information in the following ways:</p>
<h3>1: Information and content you provided</h3>
<p>We collect content, messages and other information you provided when you use our Services, including when you register for an account, create or share content, and message or communicate with others. This can include information in or about the content you provided, such as metadata, the location of a photo or the date a file was created. With your consent, we will also collect contact information you chose to upload, sync or import from your device, such as contacts, call logs or SMS records.</p>
<h3>2: Information and content we collected</h3>
<p>Your usage. We collect information automatically during your use of Service about how you use our Services, such as the types of content you view or engage with; the features you use; the actions you take; and the time, frequency and duration of your activities. For example, we log when you're using and have last used our Services, videos and other content you viewed, or games you played on our Services. <br />
Device Information. We collect and record information regarding and from the devices you used according to access you have granted when installing and using our Serives. We will strictly abide by the rules and regulations on data collection provided by the operator as to respect and your protect your rights and interests. <br />
Information we obtain from these devices includes: <br />
Device attributes: information such as the operating system, hardware and software versions, battery level, signal strength, available storage space, browser type, app and file names and types, and plugins. <br />
Device operations: information about operations and behaviors performed on the device, such as whether a window is foregrounded or backgrounded, or mouse movements (which can help distinguish humans from bots:. <br />
Identifiers: unique identifiers, device IDs and other identifiers, such as from games, apps or accounts you use <br />
Device signals: Bluetooth signals, and information about nearby Wi-Fi access points, beacons, and cell towers. <br />
Data from device settings: information you allowed us to receive through device settings you activated, such as access to your GPS location, camera or photos. <br />
Network and connections: information such as the name of your mobile operator or ISP, language, time zone, mobile phone number, IP address, connection speed and, in some cases, information about other devices that are nearby or on your network. <br />
Cookie data: data from cookies stored on your device, including cookie IDs and settings.</p>
<h3>3: Information partners provide.</h3>
<p>You can use products or service through links on your account provided by our affiliates, advertisers and app developers. As to provide you with better Services, we will display and recommended information that you may find interesting, including live streaming and gaming contents, which can be discovered and used from our Services. By using the above Services through us, you grant us permission to receive, gather and analyse personal and transaction information from our affiliates, advertisers and app developers that are legal and consented by you. <br />
Advertisers, app developers, and publishers can send us information through Tools they use, including but not limited our APIs and SDKs. These partners provide information about your activities off our Service, including information about websites you visit, purchases you make, the ads you see, and other relevant data, whether or not you have an account for our Services. For example, a game developer could use our API to tell us what games you play, or a business could tell us about a purchase you made in its store. We also receive information about your online and offline actions and purchases from third-party data providers who have the rights to provide us with your information.</p>
<h3>2. How do we use this information?</h3>
<p>We use the information to provide and support the Services. Here's how we use this information: <br />
1: To provide and optimize our Services <br />
We use the information we have to deliver, develop, test and improve our Services, including but not limited to develop research, test and troubleshoot new Services and features. <br />
2: To display and promote products or Services <br />
As to provide you with personalized information browsing and transaction services, we will dislay and push information such as ads according to informations including but not limited to your browsing and search history, device information, search preference, behavior and habit, and location. <br />
3: To enhance safety, integrity and security <br />
We use the information we have to verify accounts and activity, combat harmful conduct, detect and prevent spam and other bad experiences as to maintain the integrity of our Services and enhance your safety and data security. <br />
4: To provide measurement, analytics, and other business services<br />
We use the information we have "including your activity off our Services, such as the websites you visit and ads you see: to help advertisers and other partners measure the effectiveness and distribution of their ads and services, and understand the types of individuals who use their services and how users interact with their websites, apps, and services. <br />
5: To communicate with you <br />
We use the information we have to send you marketing messages, communicate with you about our Services, and allow you to understand our policies and terms. We also use your information to responses when you contact us. <br />
6: To research and innovate for social good <br />
We use the information we have "including from research partners we collaborate with: to conduct and support research and innovation on topics of general social welfare, technological advancement, public interest, health and well-being. <br />
7: Others <br />
If we use the information we have or collect information for some other purposes not listed on This Policy, we will inform you and get your permission in advance. <br />
3. Cookies and other similar data collection technologies: <br />
1: Cookie <br />
As ensure proper operation, provide you with a personalized and better user experience of our Services and receommded content that interests you, we will collect information on your computure or mobile devices, and store information such as your preference in data foilders through Cookie <br />
2: Other Similar Technologies <br />
Except for Cookie, we also use other similar data collection technologies such as web beacon and pixel tags. For example, when you click on link directing to content on our Services sent by us through email, we can track this click and optimize our customer service experience by understanding your preferences. A web beacon is usually a transparent image that is embedded within a website or email. We can know whether the mail has been opended through pixel tag in the webmail. If you do not wish to be tracked through the above means, you can unsubscribe from our mail list anytime. <br />
4. How is this information shared? <br />
We will share or allow third parties to collect your information from our Services under these circumstances: <br />
1: In response to a legal request <br />
We may share your information according to law and regulations, dispute resolution and litigation purposes or legal request from administration and judiciary. We will share your information when necessary to achieve the following actions: to detect, prevent and address fraud, violations of our terms or policies, or other harmful or illegal activity; to protect ourselves including our rights, property or Services, you or others including as part of investigations or regulatory inquiries; or to prevent death or imminent bodily harm <br />
2: Sharing with affiliates <br />
We may share your information to affiliates in order to provide with Services, recommend information that you might be interested in or protect the personal assets of our affiliates or other users. <br />
3: Change of ownership <br />
We may transfer your information to the new owner, controller or acquisitor in cases involving or under merger, acquisition or bankruptcy liquidation. <br />
4: Sharing with Third-Party Partners <br />
We work with third-party partners who help us provide and improve our Services as to provide you high quality Services. Here are the types of third parties we share information with: <br />
. Partners who use our analytics services <br />
We provide aggregated statistics and insights that help users and businesses to understand how usesrs are engaging with our Services. <br />
· Advertisers <br />
We provide advertisers with reports about the kinds of people seeing their ads and how their ads are performing, but we don't share information that personally identifies you (information such as your name or email address that by itself can be used to contact you or identifies who you are: without your consent. For example, we provide general demographic and interest data to advertisers (for example, that an ad was seen by a woman between the ages of 25 and 34 who lives in Madrid and likes software engineering: to help them better understand their audience. <br />
· Measurement partners <br />
We share information about you with companies that aggregate it to provide analytics and measurement reports to our partners. <br />
· Researchers and academics <br />
We also provide information and content to research partners and academics to conduct research that advances scholarship and innovation which support our business or mission, and enhances discovery and innovation on topics of general social welfare, technological advancement, public interest, health and well-being. <br />
· Other partners such as vendors and service providers <br />
We provide information and content to vendors and service providers who support our business to facilitate , such as by providing technical infrastructure services, analyzing how our Services are used, providing customer service, facilitating payments or conducting surveys. <br />
5. How do we protect your information <br />
We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your information through responsible and feasible security measures that are universal across our industry. However we are unable to promise your personal information with complete security, If you believe that your account or information is no longer secure, please notify us immediately. <br />
Please note that voluntary or public sharing of information during your use of our Services may involve personal information about you or others and carefully consider before uploading text or images involving peronsal information during post or use of our Services. <br />
6. How do you manage your own information?<br />
You can access and manage your information through the following means: <br />
Unless required otherwise by laws and regulations, you have the right to access, modify, add and erase your personal information. After deleting information on our Service, the corresponding information will be removed during the backup system update if unable to be removed immediately. <br />
You can also modify the scope of your authorization. We will no longer process your information once your withdrawn access, please note that your decision does not affect information displayed and processed before your access withdrawal. Please be aware that functions of our Services requires may require information to operate and that modifying your scope of authorization may cause inability to use our Serivces. <br />
7. How do we protect information of underage users? <br />
If you are a minor, we recommend you to ask your parents or guardians to read this Policy, and we recommend you to ask your parents or guardians for permission to use our Service or provide us your information. If you are a minor, we shall only use, share, transmit, disclose your information subject to the regulations, and necessary conditions. <br />
8. How do we operate and transfer data as part of our global services? <br />
As part of our international operations, we have the right to transfer, transmit, store or process your information globally to our Affiliated Entitie or business partners under the guidance local laws and regulations in accordance with This Policy. <br />
9. How to contact us with questions <br />
You can contact us via online customer service for any enquiries regarding This Policy.</p>
</div>
</div>
    </div>
  )
}

export default Terms
