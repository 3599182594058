import React from "react";

const Community = () => {
  return (
    <div className="color text-white pt-5 pb-5">
      <div className="  mx-5 mt-5 my-5">
        <h3 className="text-center ">
          Ban & Un-Ban standard operation procedure(SOP)
        </h3>
        <h4>Background:</h4>
        <p>
          This policy is designed in accordance with the live streaming
          guidelines for both streamers and users. Live streamers must adhere to
          the policies set forth by RoccoLive. This SOP outlines the actions
          taken for each Rocco account or UID in case of policy violations.
        </p>
        <h5>Ban procedure for LiveStream and Normal Accounts:</h5>
        <h5> Reasons for Account Bans:</h5>
        <p>
          Meaningless Content: Blank screen, black screen, sleep, if the user is
          sitting still.if the user is sitting idle, All these things are
          meaningless. <br />
          ● <b> Abusive Behavior:</b> Using offensive language towards hosts,users,or
          officials.
          <br />
          ● <b> Illegal Activities:</b> Guiding followers towards any form of illegal
          activity. <br />
          ● <b>Campaigns Against RoccoLive:</b>Initiating campaigns against RoccoLive
          or its officials. <br />
          ● <b>Threats:</b> Threatening users, followers, or officials. <br />
          ● <b>InappropriateContent:</b> Streaming explicit sexual content, nudity, or
          engaging in sexually suggestive conversations. <br />
          ● <b>Display of Banned Substances:</b>Showcasing banned or illegal
          substances, including drugs, smoking, alcohol, or firearms. <br />
          ● <b>Self-Harm:</b> Engaging in self-harm activities or encouraging harmful
          behavior. ● Blood-Related Activities:Any activities involving blood.{" "}
          <br />
          ● <b>Sectarianism:</b> You are never allowed to speak against any sect.{" "}
          <br />
          ● <b>Religion:</b> You are never allowed to speak against any religion.{" "}
          <br />
          ● <b>Profanity:</b> No one should profane any Messenger. <br />
          ● <b>Political issues:</b>You are not allowed to campaign against any
          political party or speak against it. <br />● Please note that the list
          of banning reasons may change as per RoccoLive Team’s requirements.
        </p>
        <h5>Baning Timeline:</h5>
        <p><b>First violation:</b> A warning will be issued,and the account will be given a
7-day grace period.The user must also provide a written apology letter. If the violation
occurs again, RoccoLive reserves the right to ban the account for 1 month. <br />
<b>Second violation</b> (After 1 Month Ban):Another violation after
the one 1-month ban will result in a permanent ban. <br />
<b> Creation of New Account After Ban: </b>If a user continues abusive behavior by
creating after a ban, the following actions will be taken:<br />
● 1st Instance:1-day ban<br />
● 2nd Instance:3-day ban<br />
● 3rd Instance 7-day ban<br />
● 4th Instance: permanent ban, and the user's device will also be banned.<br />
● Please be aware that these actions are subject to change based on the discretion of
the RoccoLive Team.</p>
<h5>The process for unbanning is outlined below:</h5>
<p>● <b>Step 1: </b>BD representatives are responsible for completing the designated sheet,
providing all necessary details, and specifying the reason for either the ban or unban
action associated with each UID.</p> <br />
● <b>Step 2:</b>Team head will process the request through the MODERATION Team.<br />
<h5>Note:</h5>
<p>1. No any direct query to the MODERATION TEAM will be entertained. <br />
2.If the Unbanning process deviates from the established timeline
and a request for early unbanning is made, it is imperative to provide
a valid reason for the expedited action. Failure to do so will prompt
inquiries into the Business Development (BD) representative's
conduct and connections with the user, such as friendships, business
partnerships, or potential acceptance of bribes or benefits from users,
live hosts, or agencies.</p>
      </div>
    </div>
  );
};

export default Community;
