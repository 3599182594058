import f1 from "../src/Assests/abouts-1.webp";
import f2 from "../src/Assests/aboutus-2.png";
import rocco from "../src/Assests/rocco.webp";
import about from "../src/Assests/videos/about.mp4";

const About = () => {
  return (
    <div className="color text-white">
    <div className="container">
      <div className=" container">
        <div className="row">
          <div className=" col-lg-4">
            <img
              src={f1}
              alt=""
              height="290"
              width="200"
              className="im1 mx-5"
            />
            <img src={f2} alt="" className="im2" />
          </div>
          <div className="col-lg-4 text-center mt-5 ">
            <h1 className=" mt-5" id="box1">
              Who we are <br />{" "}
              <span className="po2">
                Rocco is a vibrant short-videos platform with 100k active
                users.
              </span>
            </h1>
          </div>
          <div className=" col-lg-4">
            <video
              src={about}
              autoPlay
              muted
              loop
              className="mx-5 akhri in-left"
              width="260"
              height="570"
            ></video>
          </div>
        </div>
      </div>
      <div className="container mt-5 pt-5">
        <div className="row">
          <div className="ro2 col-lg-6">
            <h3>Rocco Video</h3>
            <hr />
            <p>
              {" "}
              "Rocco Video stands as the premier short video
              application registered mainly in Singapore, redefining the digital landscape with its innovative
              platform. Designed for a discerning audience, Rocco Video offers a
              curated space for creators to produce, share, and engage with
              compelling short-form content. Immerse yourself in a sophisticated
              blend of entertainment and culture as Rocco Video showcases the
              diverse talent and narratives of Short videos and live streaming community. Our cutting-edge
              technology ensures a seamless and immersive user experience, with
              features that empower creators and captivate audiences. Join us on
              this transformative journey as we pioneer the next era of short
              video content in the world."{" "}
            </p>
          </div>
          <div className="col-lg-6 text-center">
            <img src={rocco} alt="" className="ro1" />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default About;
