import React from "react";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";
import second from "../src/Assests/videos/second.mp4";
import third from "../src/Assests/videos/third.mp4";
import fifth from "../src/Assests/fifth.png"
import v1 from "../src/Assests/videos/v1.mp4"
import v2 from "../src/Assests/videos/v2.mp4"
import v3 from "../src/Assests/videos/v3.mp4"
import v4 from "../src/Assests/videos/v4.mp4"

const Rocco = () => {
  return (
    <>
    <div className="color text-white">
    <div className="container">
{/* Hero Section */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-4">
              <h1 className="text1 mt-5 in-down">
              Capture and share life's stories, transforming moments into adored memories with our video app.
              </h1>
              <a href="https://apps.apple.com/pk/app/roccovideo-fun-videos-go-live/id6474921968" className="btn btn-light  ">
                <FaApple className="app"/> App Store
              </a>
              <a href="https://play.google.com/store/apps/details?id=prod.thinkodigtal.com " className="btn btn-light m-4"><FaGooglePlay  className="app1"/>Google Play</a>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
                <video
                  src={v1}
                  autoPlay
                  muted
                  loop
                  className="mx-5 media2 in-left"
                  width="266.88"
                  height="553.92"
                ></video>
            </div>
          </div>
        </div>
      </section>
{/* Second Section */}
      <section className="container text-center">
        <div className="row my-5">
          <div className="col-lg-6 my-5 ">
            <video src={second} width="100%"  autoPlay muted loop className="vsecond mt-5"></video>
          </div>
          <div className="col-lg-6 my-5">
            <h1 className="text mt-5">Make</h1>
            <h1 className="textstyle"><span></span></h1>
            <h1 className="text">Shine</h1>
            <p className="para m-5">
            Everyone deserves a fair chance to voice their thoughts and share their story.
            </p>
          </div>
        </div>
      </section>
{/* Third Section */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-5">
              <h1>Explore trends worldwide</h1>
              <video src={third} autoPlay muted loop className="vthird" ></video>
              <p className="para2 mt-2">Discover and watch exciting videos you like</p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
            <div className="mx-5">
            <video
                  src={v2}
                  autoPlay
                  muted
                  loop
                  className="media4"
                  width="260"
                  height="570"
                ></video>
            </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-6">
            <img src={fifth} alt="" height={520} width={270} className="img1 media"/>
            </div>
            <div className="col-lg-6 mt-5">
              <h1 className="text5 mt-5">Capture</h1>
              <h1 className="text5">wonders in life</h1>
              <p className="para3 my-5">Capture life's moments with playful props and effortless editing.</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-5 mt-5">
              <h1 className="text5  mt-5">Have fun with</h1>
              <h1 className="text5">friends</h1>
              <p lassName="para3">Share photos, videos, and messages to keep in touch</p>
            </div>
            <div className="col-lg-6">
            <video src={v3} autoPlay muted loop className="vsixth media" height={520} width={270} ></video>
            </div>
          </div>
        </div>
      </section>
      <section>
        <h1 className="text2 text-center my-5">Stay connected with <br/> live streaming</h1>
        <p className="para3 text-center">Watch live streaming anytime anywhere</p>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
        <video src={v1} height={400} width={200}  className="mx-2 media1" autoPlay muted loop ></video>
        <video src={v2} height={400} width={200} className="mx-2 media1" autoPlay muted loop></video>
        <video src={v3} height={400} width={200} className="mx-2 media1" autoPlay muted loop></video>
        <video src={v4} height={400} width={200} className="mx-2 media1" autoPlay muted loop></video>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bgimg mt-5 pb-3">
          <h1 className="text4 pt-5 pb-5 pu">Want to know us more?</h1>
        </div>
      </section>
      </div>
    </div>
    </>
  );
};

export default Rocco;
